import spectrum from 'images/resource_slider/spectrum.png'
import photon from 'images/resource_slider/photon.png'
import clique from 'images/resource_slider/max_clique.png'
import teleport from 'images/resource_slider/teleport.png'
import bosonic from 'images/resource_slider/bosonic.png'
import state from 'images/resource_slider/StateLearning.gif'

const demosArray: Resource[] = [
  {
    image: teleport,
    title: 'Quantum Teleportation',
    difficulty: 'easy',
    link: 'https://strawberryfields.ai/photonics/demos/run_teleportation.html',
  },
  {
    image: state,
    title: 'Optimization with TensorFlow',
    difficulty: 'medium',
    link: 'https://strawberryfields.ai/photonics/demos/run_tutorial_machine_learning.html',
  },
  {
    image: spectrum,
    title: 'Vibronic Spectra',
    difficulty: 'hard',
    link: 'https://strawberryfields.ai/photonics/apps/run_tutorial_vibronic.html',
  },
  {
    image: clique,
    title: 'Maximum Clique',
    difficulty: 'medium',
    link: 'https://strawberryfields.ai/photonics/apps/run_tutorial_max_clique.html',
  },
  {
    image: photon,
    title: 'Quantum State Learning',
    difficulty: 'medium',
    link: 'https://strawberryfields.ai/photonics/demos/run_state_learner.html',
  },
  {
    image: bosonic,
    title: 'Studying Realistic Bosonic Qubits',
    difficulty: 'medium',
    link: 'https://strawberryfields.ai/photonics/demos/run_GKP_bosonic.html',
  },
]

export default demosArray

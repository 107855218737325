import * as React from 'react'

import Layout from 'components/Layout'
import Slider from 'components/slider'

import Hero from 'components/Hero'
import SideNavSection from 'components/sections/sideNav'
import SideNavigation from 'components/sections/sideNav/Navigation'

import HeadingArrowSvg from 'images/headingArrowSvg'
import demosArraySF from 'data/demos/demosArraySF'
import { Link } from 'gatsby'
import Seo from '../../components/Seo'
import { sideNavData } from 'types'

const SideNavMenu = () => {
  const sideNavInfo: sideNavData = {
    title: 'Navigation',
    links: [
      { title: 'Strawberry Fields', id: '#intro' },
      { title: 'Key Features', id: '#key-features' },
      { title: 'Getting Started', id: '#getting-started' },
      { title: 'Demos', id: '#demos' },
      { title: 'Documentation', id: '#documentation' },
    ],
  }

  return <SideNavigation sideNavInfo={sideNavInfo} />
}

const SideNavMain = () => {
  return (
    <>
      <div id="intro" className="jumptarget">
        <h3>Strawberry Fields</h3>
        <p className="mt-8.5">
          <a
            href="https://strawberryfields.ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            Strawberry Fields
          </a>{' '}
          is a full-stack Python library for constructing, simulating, and
          executing programs on photonic quantum computers. Design quantum
          algorithms and execute them directly on Xanadu&apos;s next-generation
          quantum hardware.
        </p>
        <hr />
      </div>
      <div id="key-features" className="jumptarget">
        <h3>Key Features</h3>
        <ul className="checkbox">
          <li>
            {' '}
            <strong>Quantum hardware.</strong>
            <br />
            Strawberry Fields integrates seamlessly with Xanadu Cloud, providing
            direct access to programmable quantum computers including the{' '}
            <Link to="/products/x-series">X-Series</Link> processors.
          </li>
          <li>
            {' '}
            <strong>Built in applications.</strong>
            <br />
            High-level functions for solving problems including graph and
            network optimization, machine learning, and chemistry.
          </li>
          <li>
            {' '}
            <strong>World class simulators and compilers.</strong>
            <br />
            Includes a suite of high-performance simulators—based on cutting
            edge algorithms—to compile and simulate photonic algorithms.
          </li>
          <li>
            {' '}
            <strong>Machine learning with TensorFlow support.</strong>
            <br />
            Train and optimize your quantum programs with our end-to-end
            differentiable TensorFlow backend.
          </li>
          <li>
            {' '}
            <strong>Explore different photonic architectures.</strong>
            <br />
            From time-domain multiplexing, to Gaussian Boson Sampling and
            bosonic error correction codes, explore different architectures and
            systems through our tutorials and demonstrations.
          </li>
        </ul>
      </div>
      <div className="standard-section-top-margin">
        <div id="getting-started" className="jumptarget">
          <h3>Getting Started</h3>

          <p className="mt-5">
            Install{' '}
            <a
              href="https://strawberryfields.readthedocs.io/en/stable/install.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Strawberry Fields
            </a>{' '}
            on your computer locally.
          </p>
          <p className="mt-8.5">
            Once you have Strawberry Fields up and running, have a look at our
            quickstart guides, or jump over to the{' '}
            <a
              href="https://strawberryfields.ai/photonics/demonstrations.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              tutorials
            </a>{' '}
            to see what you can do with Strawberry Fields.
          </p>
          <p className="mt-5">
            Users interested in applications of photonic quantum computers
            should check out the{' '}
            <a
              href="https://strawberryfields.ai/photonics/applications.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Applications
            </a>{' '}
            pages. Those wanting to dig deeper into the design of circuits can
            head to the{' '}
            <a
              href="https://strawberryfields.readthedocs.io/en/stable/introduction/circuits.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Circuits
            </a>{' '}
            page.
          </p>
          <p className="mt-5">
            Developers can head to the{' '}
            <a
              href="https://strawberryfields.readthedocs.io/en/stable/development/development_guide.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Development guide
            </a>{' '}
            to see how they can contribute to Strawberry Fields.
          </p>
        </div>
      </div>
      <div className="standard-section-top-margin">
        <div id="demos" className="jumptarget">
          <h3>
            <a
              href="https://strawberryfields.ai/photonics/demonstrations.html"
              target="_blank"
              className="arrow-swipe-container"
              rel="noopener noreferrer"
            >
              Demos
              <span className="arrow-swipe ml-3.75">
                <span>
                  <span>
                    <HeadingArrowSvg svgClassName="inline-block" />
                  </span>
                </span>
              </span>
            </a>
          </h3>
          <p className="mt-10">
            Take a deeper dive into quantum photonic circuit algorithms and
            hardware by exploring cutting-edge tutorials using Strawberry Fields
            and near-term quantum hardware.{' '}
          </p>
          <div className="mt-10">
            <Slider demos={demosArraySF} />
          </div>
        </div>
      </div>
      {/* DEMOS */}
      <div className="standard-section-top-margin">
        <div id="documentation" className="jumptarget">
          <h3>Documentation</h3>
          <p className="mt-10">
            Access the{' '}
            <a
              href="https://strawberryfields.readthedocs.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              full documentation
            </a>
            , or read more in our Strawberry Fields{' '}
            <a
              href="https://quantum-journal.org/papers/q-2019-03-11-129/"
              target="_blank"
              rel="noopener noreferrer"
            >
              paper
            </a>{' '}
            and our applications{' '}
            <a
              href="https://arxiv.org/abs/1912.07634"
              target="_blank"
              rel="noopener noreferrer"
            >
              paper
            </a>
            .
          </p>
        </div>
      </div>
    </>
  )
}

const StrawberryFieldsPage = () => {
  return (
    <Layout>
      <Hero
        subTitle="// Strawberry Fields"
        title="Software library for quantum photonic hardware"
        image="sf_laptop.png"
        editorial={false}
      />
      <SideNavSection nav={<SideNavMenu />} main={<SideNavMain />} />
    </Layout>
  )
}

export default StrawberryFieldsPage

export const Head = () => {
  return (
    <Seo
      title="Strawberry Fields"
      description="Simulate and execute programs on quantum photonic hardware"
      image="sf_laptop.png"
    />
  )
}

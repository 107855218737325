import * as React from 'react'

type HeadingArrowSvgProps = {
  circleClassName?: string
  svgClassName?: string
}

const HeadingArrowSvg = ({
  circleClassName,
  svgClassName,
}: HeadingArrowSvgProps) => {
  const classNameCircle = `fill-current ${
    circleClassName ? circleClassName : 'text-primary-600'
  }`
  return (
    <svg
      className={svgClassName}
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20.1189" r="20" className={classNameCircle} />
      <path
        d="M24.9999 15.119L13 27.1189"
        stroke="white"
        strokeWidth="1.84165"
        strokeLinejoin="round"
      />
      <path
        d="M16.9996 15.119H24.9995V23.1189"
        stroke="white"
        strokeWidth="1.84165"
      />
    </svg>
  )
}

export default HeadingArrowSvg
